
export const homeObj1 = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    description: 'PZMOO was founded by Kaleb Dufera, a seasoned tech professional with over a decade of hands-on experience. Kaleb excels in crafting cloud-based applications across multiple layers and stacks. Before launching PZMOO, he honed his skills as a Full Stack Engineer at Booz Allen Hamilton and played crucial roles in several early-stage startups.',
    imgStart: false,
    img: require('../../Images/about.gif').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}


export const homeObj2 = {
    id: 'mission ',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mission',
    description: 'Our mission is to lead the charge in technological innovation by developing next-generation AI-driven applications at scale. We aim to transform visionary ideas into impactful, real-world solutions that drive efficiency, enhance capabilities, and create a smarter, more connected future. Through relentless dedication to excellence and cutting-edge technology, we strive to empower individuals and organizations to reach new heights of success and innovation.',
    buttonLabel: 'Services',
    imgStart: false,
    img: require('../../Images/img5.png').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}


export const homeObj3 = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact Us',
    headline: 'Building the Future Together!',
    description: 'PZMOO is currently operating as a fully bootstrapped venture. However, the future is full of possibilities! If you are interested in following our journey and exploring potential acquisitions or investments, we would be thrilled to stay in touch!',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../Images/Account2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
}