import styled from 'styled-components'

export const ServicesContainer = styled.div`
height: 1100px;
display: flex;
flex-direction: column;
justify-content: center;
background:#010606;

@media screen and (max-width:768px) {
    height: 3150px;
}

@media screen and (max-width:480px) {
    height: 3550px;
}
`

export const ServicesWrapper = styled.div`
max-width: 1300px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
grid-gap: 16px;
padding: 0 50px;

@media screen and (max-width:1000px) {
    grid-template-columns: 1fr 1fr;
    max-width: 430px;
}

@media screen and (max-width:768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
}
`

export const ServicesCard = styled.div`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 20px;
max-height: 420px;
padding: 45px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
transition: all 0.2s ease-in-out;

&:hover {
    transform : scale(1.02);
    tansition: all 0.2 ease-in-out
    cursor: pointer;
}
@media screen and (max-width:480px) {
    max-height: 500px;
}
`

export const ServicesIcon = styled.img`
margin-top:-10px;
height: 120px;
width: 120px;
margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
margin-top: 30px
font-size: 2.5rem;
color: #fff;
margin-bottom: 35px;
align-items: center;
text-align: center;

@media screen and (max-width:480px) {
    font-size: 2rem;
}
`

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
  filter: blur(4px);
`;

export const ServicesH2Reg = styled.h2`
margin-top: -15px;
  font-size: 1.1rem;
  margin-bottom: 5px;
  text-align: center;

`;


export const ServicesP = styled.p`

font-size: .8rem;
text-align: center;
margin-bottom: 10px;
color: black;
@media screen and (max-width:480px) {
    font-size: .79rem;
}
`
