import React from 'react'
import {
    AboutContainer,
    AboutWrapper,
    AboutRow,
    BtnWrap,
    Column1,
    Column2,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    ImgWrapLogo,
    VideoBg
} from '../About/AboutElements'

import { Button } from '../../Utilities/ButtonElements';


const InfoSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    id,
    primary,
    dark,
    dark2
}) => {

    return (
        <>
            <AboutContainer lightBg={lightBg} id={id}>
                <AboutWrapper>
                    <AboutRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle><br />
                           
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrapLogo>
                                <Img src={img} alt={alt} />
                            </ImgWrapLogo>
                        </Column2>
                    </AboutRow>
                </AboutWrapper>
            </AboutContainer>
        </>
    )
}

export default InfoSection
