import React, { useState } from 'react'
import Navbar from '../Components/Navbar/Index'
import Sidebar from '../Components/SideBar/Index'
import Banner from '../Components/Banner/Index'
import InfoSection from '../Components/Info/Index';
import About from '../Components/About/Index';
import Mission from '../Components/Mission/Index'
import Contact from '../Components/Contact/Index';



import Services from '../Components/Services/Index'
import { homeObj1, homeObj2, homeObj3, homeObj4 } from '../Components/Data/Data'
import Footer from '../Components/Footer/Index'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Banner />
            <About  {...homeObj1} />
            <Mission  {...homeObj2} />

            <Services />
            {/* <InfoSection  {...homeObj2} /> */}
            <Contact  {...homeObj3} />
            <Footer />

        </>
    )
}

export default Home
